.homepage {
  .collapse {
    ._header {
      .one {
        width: 400px;
        color: #007bff;
        word-break: break-word;
        @media screen and (max-width: 500px) {
          width: 200px;
        }
      }
    }
  }
}
