.layout {
  .hide-sm {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .header {
    height: 64px;
    background-color: #343a40;
    color: white;
    width: 100%;
    padding: 0 16px;

    .right_xl {
      display: flex;
      margin: -0.6rem 0 0 0;
      width: 15vw;
      justify-content: space-around;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: none;
      }
      @media screen and (max-width: 1024px) {
        width: 25vw !important;
      }
      @media screen and (max-width: 1440px) {
        width: 20vw;
      }
      &__upload,
      &__report,
      &__user {
        color: #fff;
      }
    }

    .right_without-login {
      display: flex;
      margin: -0.6rem 0 0 0;
      width: 5vw;
      justify-content: space-around;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &__upload,
      &__report,
      &__user {
        color: #fff;
      }
    }

    .right_sm {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    img {
      width: 100%;
    }

    &_link {
      color: #fff;
      padding: 0 10px;
      font-weight: normal;

      &:hover {
        color: white;
      }
    }
  }

  .body {
    background-color: white;
    padding: 24px 0;
    .inner {
      margin: 0 auto;
      width: 80%;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
}
