.report {
  &__title {
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: 425px) {
      font-size: 1.5rem;
    }
  }
  &__name {
    margin-bottom: 0;
    @media screen and (max-width: 425px) {
      font-size: 1.5rem !important;
      margin-bottom: 1rem;
    }
  }
  &__image > img {
    width: 30vw;
    @media screen and (max-width: 768px) {
      width: 90vw;
    }
  }
  &__building {
    margin-top: 0.8rem;
    @media screen and (max-width: 768px) {
      margin: 1rem 0 0.5rem 0;
    }
    @media screen and (max-width: 425px) {
      margin: 1rem 0 0.5rem 0;
      font-size: 1.2rem;
    }
  }
  &__generate {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    &--icon {
      font-size: 17px;
    }
  }
  &__wrapped {
    overflow-x: scroll;
  }
}
.defect-table {
  width: 100%;
  margin: 40px 0;
  border: 1px solid;

  @media screen and (max-width: 768px) {
    display: block;
    max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
  }

  &__id,
  &__body,
  &__idData,
  &__bodyData {
    border: 1px solid;
    padding: 10px;
  }
  &__header {
    background-color: #000;
    color: #fff;
  }
  &__id {
    width: 4rem;
  }
  &__body {
    width: 45rem;
  }
  &__idData {
    width: 4rem;
    text-align: center;
  }
  &__bodyData {
    &__list {
      width: 50rem;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 425px) {
        width: 35rem;
      }
    }
    &__url {
      width: 20rem;
      margin: 1rem;
      display: block;
      @media screen and (max-width: 425px) {
        width: 15rem;
      }
    }
  }
  &__children {
    width: 90%;
    margin: 1rem;
    text-align: center;
    border: 1px solid;
    th,
    td {
      border: 1px solid;
    }
  }
}
.defect-photos-upload {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
  color: #129d12;
}
