body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hide-sm {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.show-sm {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.hiding-form {
  margin: 0 !important;
  height: 0;
}

@import "./layout.less";
@import "./analyze.less";
@import "./homepage.less";
@import "./report.less";

.w-100 {
  width: 100% !important;
}

.ant-divider-horizontal {
  border-top: 3px solid rgba(0, 0, 0, 10) !important;
}

.boder-bolder {
  .ant-table-cell {
    background: white !important;
  }
}

.report-nen-table-sn {
  background-color: black;
  color: white;
}

.p-0 {
  padding: 0 !important;
}

.ant-table-tbody > tr > td.ant-table-cell-row-hover.report-nen-table-sn {
  background: black !important;
}
