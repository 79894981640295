.analyze {
  &_relocate {
    &_button-on {
      background-color: green !important;
    }
    &_button-off {
      visibility: visible;
    }
  }
  &_box {
    margin: 16px 0;
  }
  &_img-fluid {
    max-width: 100%;
    height: auto;
  }
  &_svg-fluid {
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    cursor: crosshair;
  }
  &_defect {
    stroke-opacity: 0.5;
    stroke-width: 10;
    fill-opacity: 0.15;
    transition: 200ms;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

&_defect-red {
    border-color: red;
    stroke: red;
    fill: red;
}

&_defect-green {
    border-color: green;
    stroke: green;
    fill: green;
}
}